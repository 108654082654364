// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@import "@angular/material/theming";

@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// @import "/node_modules/flag-icons/css/flag-icons.min.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$app-typography: mat-typography-config($font-family: 'poppins, Manrope, quicksand, Montserrat, Helvetica Neue, sans-serif',
    $display-4: mat-typography-level(112px, 112px, 300),
    $display-3: mat-typography-level(56px, 56px, 400),
    $display-2: mat-typography-level(45px, 48px, 400),
    $display-1: mat-typography-level(34px, 40px, 400),
    $headline: mat-typography-level(24px, 32px, 400),
    $title: mat-typography-level(20px, 32px, 500),
    $subheading-2: mat-typography-level(16px, 28px, 400),
    $subheading-1: mat-typography-level(15px, 24px, 400),
    $body-2: mat-typography-level(16px, 24px, 500),
    $body-1: mat-typography-level(16px, 20px, 400),
    $caption: mat-typography-level(12px, 20px, 400),
    $button: mat-typography-level(16px, 16px, 400),
    // Line-height must be unit-less fraction of the font-size.
    $input: mat-typography-level(16px, 1.25, 400),
  );

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Jubaan-Frontend-primary: mat.define-palette(mat.$indigo-palette);
$Jubaan-Frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Jubaan_Frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Jubaan-Frontend-theme: mat.define-light-theme((color: (primary: $Jubaan-Frontend-primary,
        accent: $Jubaan-Frontend-accent,
        warn: $Jubaan-Frontend-warn,
      ),
      typography:$app-typography ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Jubaan-Frontend-theme);

/* You can add global styles to this file, and also import other style files */
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  font-size: 1rem;
}

body {
  font-family: "Poppins", "Manrope", "Quicksand", "Montserrat", sans-serif;
  background-color: #F8FAFC;
}

pre {
  font-family: inherit;
  white-space: pre-line;
  margin: 0;
  // line-height: 1;
}

.account-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2rem;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: left;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.cart-menu {
  width: 40vw !important;
  position: relative;
  padding-top: 8px; // Space for the triangle 
  overflow: visible;
  border: 1px solid #2390cf;
  margin-top: 8px;
  height: auto;
  max-width: 25vw;

  .menu-triangle {
    position: absolute;
    top: -12px;
    left: 87%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #2390cf; // Adjust color to match your menu background

    // filter: drop-shadow(0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12));

    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: 0px; // Slight offset to simulate shadow below the triangle
    //   left: 88%;
    //   transform: translateX(-50%);
    //   width: 0;
    //   height: 0;
    //   border-left: 12px solid transparent;
    //   border-right: 12px solid transparent;
    //   border-bottom: 12px solid rgba(0, 0, 0, 0.2); // Shadow color
    //   z-index: 1; // Below the triangle itself
    // }
  }

  .menu-inner {
    padding: 16px;
    max-width: 25vw;
    max-height: 40vh;
  }
}

.line {
  height: 1px;
  margin-top: 2%;
  margin-bottom: 2%;
  border: 0;
  border: 0;
  border-bottom: 2px solid rgb(202, 202, 202);
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &.horizontal-form {
    flex-direction: row;
  }
}

.page-wrapper {
  // max-width: 1340px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .page-header {
    flex: 0 0 auto;
    // display: flex;
    // align-items: center;

    .mat-toolbar.mat-toolbar-single-row {
      padding: 0;
      line-height: unset;
      gap: 0.5rem;

      h1 {
        font-size: 2rem;
        font-weight: 600;
        color: #6F7F84;
      }

      .spacer {
        flex: 1;
      }

      ;
    }

    .page-header-inner {
      flex: 1;
    }

    .page-header-actions {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
    }
  }

  .page-content {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // margin-top: 1.5rem;

  .mat-button {
    padding: 0;
  }

  .wrapper-inner {
    h1 {
      margin-top: 0;
    }
  }
}

.font-green {
  color: #096609;
}

.font-red {
  color: #e51816
}

.font-gray {
  color: rgb(141, 141, 140);
}

.sab-title {
  font-size: 1.1rem;
  color: rgba(1, 132, 186, 1);
}

.app-header {
  .mat-toolbar.mat-toolbar-single-row {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid #D0D3D6;
  }
}

.mat-mdc-paginator {
  background: transparent;
}

.mat-mdc-menu-item {
  min-height: 36px !important;
}

.mat-toolbar.mat-toolbar-single-row {
  background-color: transparent;
}





.mat-button-toggle:hover {
  transition: 200ms ease-in-out;
  background-color: #0184BA;
  color: #ffffff;
}


.mat-button-toggle-checked,
.active {
  background-color: #0184BA !important;
  color: #ffffff !important;
}

.mat-mdc-table {
  background-color: inherit;

  .mat-mdc-header-row {
    height: 2rem !important;
    min-height: 0;
    // background-color: #F4F6F6;
    background-color: #eee;
    border-radius: 4px;

    .mat-mdc-header-cell {
      height: auto;
      border: transparent;
      color: #6F7F84;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .mat-mdc-row {
    height: 64px !important;
    margin: 0.5rem 0;
    // border: 1px solid #D0D3D6;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    cursor: pointer;

    .mat-mdc-cell {
      border: none;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &.mat-cell-button {
        justify-content: flex-end;
      }

      .mat-mdc-chip {
        border-radius: 4px;
        height: 26px;

        &.not-active {
          background-color: #E5F4FF
        }
      }
    }
  }
}

.mat-mdc-checkbox {
  label {
    font-size: 0.875rem;
  }
}

.mdc-button {
 

  &.button-wide {
    width: 225px;
  }

  &.button-medium {
    width: 140px;
  }

  &.button-small {
    width: auto;
  }

  &.mat-mdc-unelevated-button {
    height: 40px;    
    &.mat-primary {
      color: #fff;
      background-color: #0184BA;
      border: 1px solid #0184BA;
    }

    &.mat-default {
      color: #0184BA;
      background-color: #F4F6F6;
      border: 1px solid #0184BA;
    }

    &[disabled] {
      color: #fff;
      background-color: #B8BFC8;
      border: 1px solid #B8BFC8;
    }
  }

  &.mat-mdc-raised-button {
    height: 40px;

    &.mat-primary {
      color: #fff;
      background-color: #0184BA;
      border: 1px solid #0184BA;
    }

    &.mat-default {
      color: #0184BA;
      background-color: #F4F6F6;
      border: 1px solid #0184BA;
    }

    &[disabled] {
      color: #fff;
      background-color: #B8BFC8;
      border: 1px solid #B8BFC8;
    }
  }

  &.mat-mdc-outlined-button {

    &.mat-primary {
      color: #0184BA;
      background-color: #F4F6F6;
      border: 1px solid #0184BA;
    }
  }
}

mat-tab-group{
  
  .mat-mdc-tab-body-wrapper // angular >= 16
    {
      flex-grow: 1;
    }

    .mat-mdc-tab-body-content{
      overflow: hidden;
    }
}

mat-radio-group {
  display: flex;
  flex-direction: column;
  gap: .25rem;
  // .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  //   border-color:rgb(66, 134, 244); 
  // }

  // .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle{
  //   color:rgb(66, 134, 244);
  //   background-color:rgb(66, 134, 244) ;
  // }

  // .mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  //     background-color:rgb(255, 37, 37,.26);
  // }

  mat-radio-button {
    width: 100%;
    border-color: rgb(66, 134, 244);

    .mdc-form-field {
      width: 100%;

      label {
        width: 100%;

        .radio-button-inner {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;

          .radio-button-text {
            color: #344054;
            font-weight: 500;
          }

          .radio-button-icon {
            width: 46px;
            height: 32px;
            background-color: #F4F6F6;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            color: #195B8A;
          }
        }

        // .radio-button-inner {
        //   margin: .25rem 0;
        //   padding: .5rem;
        //   width: 100%;
        //   display: flex;
        //   flex-direction: row;
        //   align-items: center;
        //   gap: 1rem;
        //   border: 1px dotted #adabab;
        //   border-radius: 4px;
        //   transition: all 750ms ease-in-out;

        //   &.selected {
        //     border: 1px solid green;
        //     background: #4eda4e2b;
        //     transition: all 750ms ease-in-out
        //   }
        // }

      }
    }
  }
}

// mat-button-toggle-group {
//   height: 48px !important;
//   align-items: center !important;

//   .mat-button-toggle-button {

//     .mat-button-toggle-label-content {
//       line-height: 30px;
//       padding: 0 .5rem;
//     }
//   }
// }

mat-datepicker-toggle {
  .mat-mdc-icon-button {

    svg {
      vertical-align: middle;
    }

    &.mat-mdc-button-base {
      width: 40px;
      height: 40px;
      padding: 6px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    .mat-mdc-button-touch-target {
      width: 40px;
      height: 40px;
    }
  }
}

mat-card {

  &.mat-mdc-card {
    // box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 14px;
  }

  .mat-mdc-card-header {
    background-color: #f2f6fa;
    padding: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 1px solid #cdecff;
    position: relative;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;

    .mat-mdc-fab {
      position: absolute;
      bottom: -35%;
      right: 1%;
    }

    .mat-mdc-card-avatar {
      width: 56px;
      height: auto;
      background-color: #40657b;
      margin: 0;
    }

    .mat-card-header-inner {
      flex: 1;
    }
  }

  .mat-mdc-card-content {
    padding: 1rem;

    .mat-mdc-tab {
      position: relative;
    }
  }

  // .mat-mdc-card-actions{
  //   background-color: #93ceff;
  // }
}

.mat-mdc-form-field {

  width: 100%;
  font-size: 14px;

  &.mat-form-field-disabled {
    border: initial;
    background-color: #ebf3fc;
  }

  &.xs {
    flex: 0 0 100px;
    // width: 100px;
  }

  &.small {
    flex: 0 0 150px;
    // width: 180px;
  }

  &.medium {
    width: 260px;
  }

  &.large {
    width: 350px;
  }

  button {
    width: 100%;
    padding: 1.25rem 1rem
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0;
    padding-left: .25rem;
  }

  .mat-mdc-text-field-wrapper {    

    .mat-mdc-form-field-flex {

      .mdc-notched-outline {

        .mdc-notched-outline__leading {
          border-radius: 0;
        }


        .mdc-notched-outline__trailing {
          border-radius: 0;
        }
      }

      &:not(.mdc-text-field--disabled) {
        .mdc-notched-outline {

          .mdc-notched-outline__leading {
            border-color: rgba(0, 0, 0, 0.1);
          }

          .mdc-notched-outline__notch {
            border-color: rgba(0, 0, 0, 0.1);
          }

          .mdc-notched-outline__trailing {
            border-color: rgba(0, 0, 0, 0.1);
          }
        }
      }

      .mat-mdc-floating-label {
        top: 22px;
        font-weight: 500px;
        transform: translateY(calc(-50% - 0.5px));
      }

      .mat-mdc-form-field-icon-suffix {
        display: flex;
        flex-direction: row;
        color: rgba(0, 0, 0, .54);

        .mat-mdc-icon-button.mat-mdc-button-base {
          width: 36px;
          height: 36px;
          padding: 6px;

          .mat-icon {
            height: 20px;
            width: 20px;
            overflow: hidden;
            font-size: 20px;
          }
        }
      }

      .mat-mdc-form-field-icon-prefix {
        .mat-icon {
          padding: 6px;
          width: 2rem;
        }
      }

      .mat-mdc-form-field-infix {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: auto;
        min-height: unset;

        .mat-mdc-form-field-input-control {
          color: #445053;
        }
      }
    }


  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing{
  border-color: #0182ba75 !important;
}
.mat-mdc-option {
  background-color: #FFF;

  &.filter-option {
    .mat-pseudo-checkbox-full {
      display: none !important;
    }
  }
}

.mat-option-text {
  overflow: auto;
  text-overflow: unset;
}

.mat-option-inner {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}





.mat-mdc-icon-button {
  &:hover {
    .mat-mdc-button-persistent-ripple::before {
      opacity: 1;
      background-color: transparent;
    }
  }

  &.ctrl-clear-button {
    height: 44px;
    width: 44px;
    padding: 12x;

    .mat-icon {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 1.25rem;
    }
  }
}


// .cdk-overlay-container .cdk-overlay-pane {
//   width: fit-content !important;
// }

.form {
  display: flex;
  flex-direction: column;
}

.form-controls {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  // align-items: center;
}

.form-control {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  &.flex-column {
    flex-direction: column;
    gap: unset;

    mat-label {
      font-size: .875rem;
      margin-left: .25rem;
      color: #6F7F84;
      line-height: 1.5;
    }
  }

  mat-button-toggle-group {
    height: 100%;
    align-items: center;
    border-color: rgba(0, 0, 0, 0.38);

    mat-button-toggle {
      height: 100%;
      display: flex;

    }
  }
}

.flex-full {
  flex: 1;
}

.flex-half {
  flex: 0.5
}

.flex-quarter {
  flex: 0.25
}

.flex-auto {
  flex: 0 0 auto;
}

.small-font {
  font-size: 0.875rem;
}

.flex-center {
  align-items: center;
}

.link {
  color: #0184BA;
  text-decoration: none;
  cursor: pointer;


  &.link-flex {
    display: flex;
    gap: 0.15rem;
    font-size: 0.875rem;
    align-items: center;

    .mat-icon {
      width: 0.875rem;
      height: 0.875rem;
      font-size: 0.875rem;
    }
  }

}

//Dialogs Styles

.dialog-wrapper {
  padding: 1.5rem 2.5rem;

  .dialog-title {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eee;
    color: #6F7F84;

    h3 {
      flex: 1;
      padding: 0;
    }
  }

  .dialog-actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-top: 1rem;
    border-top: 1px solid #eee;
    padding-top: 1rem;
    gap: 0.5rem;

    // #btn-close{

    // }
  }

  .dialog-content {
    .form-section-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h2,
      h3,
      h4 {
        margin: 0;
      }
    }

  }
}


.cdk-global-overlay-wrapper {
  overflow: auto;
}

// .mat-mdc-dialog-container {

//   .mdc-dialog__title {
//     margin-bottom: 2.5rem;
//     padding: 0;

//     &:before {
//       height: auto;
//       display: block;
//     }

//     h3 {
//       margin: 0;
//     }
//   }

//   .mdc-dialog__content {
//     padding: 0 !important;
//   }

//   .mdc-dialog__actions {
//     padding: 0;
//     width: 100%;

//     .dialog-actions-inner {
//       width: 100%;
//       display: flex;
//       flex-direction: row-reverse;
//       gap: 1rem;
//     }
//   }
// }

.edit-device-info-dialog {
  width: 600px;
}

.order-device-dialog {
  width: 650px;
}

.file-upload-dialog {
  width: 450px;
  max-width: 100%;
}

.patient-details-dialog {
  width: 500px;
}

.privacy-policy-dialog {
  width: 650px;
}

.register-existing-device-dialog {
  width: 600px;
}

.device-invitation-dialog {
  width: 600px;
}


/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(141, 141, 140);
  border-radius: 10px;
}